import React from 'react'
import Layout from '../../components/layout'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
export const query = graphql`
  query MediaQuery {
    navLogos: allFile(filter: { relativeDirectory: { eq: "media" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    timeImages: allFile(filter: { relativeDirectory: { eq: "media/time" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    shapeImages: allFile(filter: { relativeDirectory: { eq: "media/shape" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    oprahImages: allFile(filter: { relativeDirectory: { eq: "media/oprah" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

class Media extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const vogueLogo = this.props.data.navLogos.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('vogue.png') !== -1
    )[0].node.childImageSharp.fluid
    const kellyRyanLogo = this.props.data.navLogos.edges.filter(
      edge =>
        edge.node.childImageSharp.fluid.src.indexOf('kellyryan.png') !== -1
    )[0].node.childImageSharp.fluid

    const timeLogo = this.props.data.navLogos.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('time.png') !== -1
    )[0].node.childImageSharp.fluid

    const gqLogo = this.props.data.navLogos.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('gq.png') !== -1
    )[0].node.childImageSharp.fluid

    const shapeLogo = this.props.data.navLogos.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('shape.png') !== -1
    )[0].node.childImageSharp.fluid

    const oprahLogo = this.props.data.navLogos.edges.filter(
      edge => edge.node.childImageSharp.fluid.src.indexOf('oprah.png') !== -1
    )[0].node.childImageSharp.fluid
    return (
      <div className="flex flex-row">
        <div className="flex-col w-full ml-auto">
          <Layout>
            <section className="section-padding-large biq-bg-prim-light biq-text-white">
              <div className="container">
                <h1>Media / Press</h1>
              </div>
            </section>
            <div className="flex flex-row flex-wrap">
              <div
                className="flex flex-row xl:flex-col flex-wrap w-full xl:w-1/4 biq-bg-gray h-full xl:h-screen xl:sticky top-0 xl:pt-32"
              >
                <div
                  className="flex place-items-center justify-center w-1/2 lg:w-1/3 xl:w-full"
                  style={{ maxHeight: 'calc(100% / 6)' }}
                >
                  <AnchorLink
                    to="/media/#vogueContent"
                    className="w-1/3 media-icon"
                    style={{ maxHeight: 'calc(100% / 6)' }}
                  >
                    <Img
                      fluid={
                        vogueLogo
                      }
                    />
                  </AnchorLink>
                </div>
                <div
                  className="flex place-items-center justify-center w-1/2 lg:w-1/3 xl:w-full"
                  style={{ maxHeight: 'calc(100% / 6)' }}
                >
                  <AnchorLink
                    to="/media/#kellyRyanContent"
                    className="w-1/3 media-icon"
                    style={{ maxHeight: 'calc(100% / 6)' }}
                  >
                    <Img
                      fluid={
                        kellyRyanLogo
                      }
                    />
                  </AnchorLink>
                </div>
                <div
                  className="flex place-items-center justify-center w-1/2 lg:w-1/3 xl:w-full"
                  style={{ maxHeight: 'calc(100% / 6)' }}
                >
                  <AnchorLink
                    to="/media/#timeContent"
                    className="w-1/3 media-icon"
                    style={{ maxHeight: 'calc(100% / 6)' }}
                  >
                    <Img
                      fluid={
                        timeLogo
                      }
                    />
                  </AnchorLink>
                </div>
                <div
                  className="flex place-items-center justify-center w-1/2 lg:w-1/3 xl:w-full"
                  style={{ maxHeight: 'calc(100% / 6)' }}
                >
                  <AnchorLink
                    to="/media/#gqContent"
                    className="w-1/3 media-icon"
                    style={{ maxHeight: 'calc(100% / 6)' }}
                  >
                    <Img
                      fluid={
                        gqLogo
                      }
                    />
                  </AnchorLink>
                </div>
                <div
                  className="flex place-items-center justify-center w-1/2 lg:w-1/3 xl:w-full"
                  style={{ maxHeight: 'calc(100% / 6)' }}
                >
                  <AnchorLink
                    to="/media/#shapeContent"
                    className="w-1/3 media-icon"
                    style={{ maxHeight: 'calc(100% / 6)' }}
                  >
                    <Img
                      fluid={
                        shapeLogo
                      }
                    />
                  </AnchorLink>
                </div>
                <div
                  className="flex place-items-center justify-center w-1/2 lg:w-1/3 xl:w-full"
                  style={{ maxHeight: 'calc(100% / 6)' }}
                >
                  <AnchorLink
                    to="/media/#oprahContent"
                    className="w-1/3 media-icon"
                    style={{ maxHeight: 'calc(100% / 6)' }}
                  >
                    <Img
                      fluid={
                        oprahLogo
                      }
                    />
                  </AnchorLink>
                </div>
              </div>
              <div className="flex flex-col w-full xl:w-3/4">
                <section
                  id="vogueContent"
                  className="section-padding-small biq-bg-white biq-text-prim px-4 lg:px-32"
                >
                  <div className="container">
                    <div className="flex flex-col">
                      <h2 className="biq-font-semibold">Vogue</h2>
                      <div className="flex flex-col">
                        <div className="flex font-bold">by Victoria Hall</div>
                        <div className="flex">April 9, 2020</div>
                      </div>
                      <div className="flex flex-col">
                        <h3 className="text-left mt-6">
                          HOW EFFECTIVE CAN BREATHING BE FOR STRESS?
                    </h3>
                        <div className="flex flex-col">
                          <p className="mt-6">
                            We’re by no means suggesting that taking in a couple of
                            deeper breaths is going to radically change your life,
                            but in moments of stress – when you feel your chest
                            tightening and head spinning – it can definitely bring
                        about a soothing sense of calmness.{' '}
                          </p>
                          <p className="mt-6">
                            “It is a magical tool which enables us to ‘trick’ our
                            body into feeling calmer, more relaxed or more
                            energised,” says Kat Farrants, yoga expert and founder
                            of Movement For Modern Life. “We can change our mood and
                        our physiology, plus how we respond to things.”{' '}
                          </p>
                          <p className="mt-6">
                            Without sounding cliché, it’s all about slowing it down
                            and focusing on the breath. “Slowing down your breathing
                            activates the parasympathetic nervous system, which is
                            the opposite to your sympathetic nervous system, more
                            commonly known as the fight or flight stress mode,”
                            explains Fiona Lamb, clinical hypnotherapist at the Hale
                            Clinic. “Both states cannot be accessed at the same
                            time, so when this system is triggered we essentially
                            override our stress response – cue a more relaxed body
                        and mind.”{' '}
                          </p>
                          <p className="mt-6">
                            While the science around breathwork is still quite thin
                            on the ground, what there is does seem to support the
                            claims that advocates make. In 2018, a study by Trinity
                            College Dublin found that the way we breathe can affect
                            the chemistry in our brains, and help improve our
                            attention and focus. “Our research found that there is
                            evidence to support the view that there is a strong
                            connection between breath-centred practices and a
                            steadiness of mind," says Ian Robertson, co-director of
                        the Global Brain Health Institute at Trinity.{' '}
                          </p>
                        </div>
                        <h3 className="text-left mt-6">
                          5 KEY BREATH PATTERNS TO TRY
                    </h3>
                        <p className="mt-6">
                          Every day we take between 22,000 and 29,000 breaths, but
                          most of us breathe too quickly and limit our inhalations
                          and exhalations to our chests, rather than our bellies –
                          especially when we’re stressed. So, how should we be
                          breathing?
                    </p>
                        <h3 className="text-left mt-6">USE YOUR NOSE</h3>
                        <p className="mt-6">
                          Do you breathe with your nose or your mouth? If you spend
                          more time in the latter camp, Bostock challenges you to
                          switch it up: “Use your nose, it’s what it’s there for and
                          it helps you to slow things down.”
                    </p>
                        <h3 className="text-left mt-6">GO DEEP </h3>
                        <p className="mt-6">
                          If your shoulders lift and your chest puffs out when you
                          breathe, you should focus on breathing down into your
                          belly, expanding your lower ribs. “At the moment, you’re
                          using your neck, shoulders and upper chest muscles to
                          expand your chest to breathe in air, but they’re designed
                          to be used in short bursts when we need to breathe
                          quickly, rather than 24/7,” says Bostock. Shallow
                          breathing can also result in neck, shoulder and back pain.
                    </p>
                        <h3 className="text-left mt-6">EMPHASISE YOUR EXHALE</h3>
                        <p className="mt-6">
                          Inhaling with deep belly breaths is only half of the
                          technique. “Make sure that your exhale is good and you can
                          feel your body is narrowing, almost like a crunch. You
                          want to make sure that you’ve got all the stale air out,”
                          says US-based clinical psychologist and founder of The
                      Breathing Class, Dr Belisa Vranich.{' '}
                        </p>
                        <h3 className="text-left mt-6">THE SIX-SECOND RULE </h3>
                        <p className="mt-6">
                          “Start by inhaling through your nose for six seconds and
                          then exhale through your nose for six seconds,” says
                          Bostock. “Repeat this cycle for at least three minutes, if
                          not longer, if you can.” If six seconds sounds like a big
                          commitment, Bostock suggests starting with three or four
                      seconds, and building up to six over time.{' '}
                        </p>
                        <h3 className="text-left mt-6">TUNE IN </h3>
                        <p className="mt-6">
                          If you want more guidance to get you going, we highly
                          recommend tuning into Bostock’s regular live-streamed
                          breathwork sessions. It’s also worth checking out
                          @Breathpod on Instagram, which is headed up by the
                          breathing coach Stuart Sandeman, and also offers real-time
                      breathing classes.{' '}
                        </p>
                        <p className="mt-6">
                          And, while your worries won’t disappear completely with
                          one deep breath, you might just be surprised by how much
                          better you feel after three minutes. Plus, it’s free of
                          charge, requires zero equipment and can be tried from the
                          comfort of your couch. Enough to persuade even sceptics to
                      give it a try.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="kellyRyanContent"
                  className="section-padding biq-bg-prim-light biq-text-white px-4 lg:px-32"
                >
                  <div className="container">
                    <h2 className="biq-font-semibold"> Kelly and Ryan</h2>
                    <div className="flex mt-8 w-full justify-center">
                      <div className="block">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/XrK3t0siRwk?rel=0"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                    <div className="flex justify-center mt-8">
                      <a
                        className="btn-prim"
                        href="https://kellyandryan.com/video/jannewary-breathing-101/"
                        target="_blank"
                      >
                        {' '}
                    Watch Here
                  </a>
                    </div>
                  </div>
                </section>
                <section
                  id="timeContent"
                  className="section-padding biq-bg-white biq-text-prim px-4 lg:px-32"
                >
                  <div className="container">
                    <h2 className="biq-font-semibold">Time</h2>
                    <div className="flex flex-row flex-wrap justify-center">
                      <div className="flex w-6/12 justify-center mt-8">
                        <div className="w-11/12">
                          <Img
                            fluid={
                              this.props.data.timeImages.edges[0].node
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                      <div className="flex w-6/12 justify-center mt-8">
                        <div className="w-11/12">
                          <Img
                            fluid={
                              this.props.data.timeImages.edges[2].node
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-center">
                      <div className="flex w-11/12 justify-center mt-8">
                        <div className="w-11/12">
                          <Img
                            fluid={
                              this.props.data.timeImages.edges[1].node
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="gqContent"
                  className="section-padding  biq-bg-prim-light biq-text-white px-4 lg:px-32"
                >
                  <div className="container">
                    <h2 className="biq-font-semibold">GQ</h2>
                    <h3 className="text-left">
                      You're breathing all wrong - Here's how to do it right
                </h3>
                    <div className="flex flex-col">
                      <div className="flex font-bold">by Brennan Kilbane</div>
                      <div className="flex">March 22, 2018</div>
                      <p className="mt-6">
                        Are you doing anything right now? Of course you’re
                        not—you’re reading wellness articles on the Internet. Why
                        don’t you take a moment out of your rigorous afternoon of
                        self-improvement and indulge in a long, deep breath? Inhale
                        for six whole seconds, expel that air, and record, very
                        specifically, what your body is doing while this is
                        happening. Do your chest and shoulders rise up to your chin?
                        Does your gut buckle in a little bit on the inhale, pressing
                        against your abdominal wall? If so, congratulations: You are
                        a vertical breather! You are also doing it very, very wrong.
                  </p>
                      <p className="mt-6">
                        This is the information Dr. Belisa Vranich delivered unto me
                        one day, in a sparsely decorated rent-a-room near Madison
                        Square Park. She's an expert on how to breath right. Using a
                        tape measure, Dr. Vranich recorded my girth at the crest and
                        trough of my breathing pattern. Then she scribbled some
                        figures on a sheet of paper and typed a ratio into her
                        calculator. 29.99 (repeating) was my score. That translates
                        to a 29% on my Breathing Test, which is a failing grade. I
                        would have performed better if I had not breathed at all.
                  </p>
                      <p className="mt-6">
                        Dr. Vranich, a self-minted Breathing Instructor, got her
                        doctorate in child psychology from NYU, and this is the
                        least of her accomplishments. During the course of our
                        hour-and-a-half long meeting, which was surreally
                        transformative, she casually makes reference to about six
                        thousand jobs she’s had in her lifetime. Jobs which include:
                        Health and Sex Editor at Men’s Fitness. Director of Public
                        Education at the Mental Health Association of NYC. Director
                        of something else for Gold’s Gym that I can’t remember, but
                        it was very impressive and eminent in the field. At our
                        meeting she wore gold hoops and a “Dr. Belisa” branded tee.
                        Her voice—affectionate and deep—lulled me into a fugue state
                        of blind faith within 15 minutes of meeting her, as she
                        informed me that I had spent about 20 years of my life
                        breathing incorrectly.
                  </p>
                      <p className="mt-6">
                        According to Dr. Vranich, we are all born with proper
                        breathing habits, and we amble through the first five or so
                        years of our lives inhaling and exhaling with proper form.
                        But after that, as we start to bear the physical and
                        emotional burdens of maturity, our breathing changes. We
                        become anxious, pulling tension into our shoulders. We suck
                        in our stomachs, acutely aware of how others perceive us.
                        Before long, we are taking shorter, less substantial breaths
                        from our chest—the habit quickly becomes second-nature.
                        Almost like breathing.
                  </p>
                      <p className="mt-6">
                        But a small comfort: According to Dr. Vranich, 9.5/10 people
                        are just like you and I—wrong. We breathe up-and-down, like
                        actors of Claritin commercials. When we inhale, our
                        shoulders lift away from our anatomical center, where breath
                        belongs. Proper breath is horizontal; it happens at the
                        belly, and engages our diaphragm, a “frisbee-sized flank
                        steak”—this is a metaphor Dr. Vranich is fond of—that
                        bisects our torso. If you breathe vertically, you ignore
                        your diaphragm, which sits directly between your heart and
                        your gut. When it’s moving, everything else is happy. When
                        it’s not, problems arise. Nothing here is particularly
                        life-threatening—vertical breathing is not a chronic
                        condition that threatens your existence—but it has been
                        linked to high blood pressure, digestive problems, back
                        pain, and general stress. And its antidote is simple: An
                        hour with Dr. Vranich.
                  </p>
                      <p className="mt-6">
                        Dr. Vranich’s clientele includes MMA fighters and
                        professional singers, magazine editors and golf
                        celebrities—plus regular celebrities, I think, although she
                        wouldn’t confirm. Her life’s work exists at the apex of
                        stress reduction and performance enhancement, which is why
                        the Department of Homeland Security is a client. During
                        goofy, emotional one-on-one sessions, Dr. Vranich will
                        change the way you breathe forever. When you leave,
                        everything changes, and I am not being dramatic—your
                        posture, your gait, the way you speak, the flow of oxygen to
                        your brain is slightly, but perceptibly different.
                  </p>
                      <div className="mt-6 flex justify-self-center">
                        Source:{' '}
                        <a
                          href="https://www.gq.com/story/how-to-breath-right"
                          target="_blank"
                        >
                          https://www.gq.com/story/how-to-breath-right
                    </a>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="shapeContent"
                  className="section-padding biq-bg-white biq-text-prim px-4 lg:px-32"
                >
                  <div className="container">
                    <h2 className="biq-font-semibold">Shape</h2>
                    <h3>
                      PEEL AWAY STRESS. ACHIEVE INNER PEACE DURING YOUR MORNING
                      BEAUTY ROUTINE.
                </h3>
                    <div className="flex flex-row flex-wrap justify-center">
                      <div className="flex w-6/12 justify-center mt-8">
                        <div className="w-11/12">
                          <Img
                            fluid={
                              this.props.data.shapeImages.edges[0].node
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                      <div className="flex w-6/12 justify-center mt-8">
                        <div className="w-11/12">
                          <Img
                            fluid={
                              this.props.data.shapeImages.edges[1].node
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="oprahContent"
                  className="section-padding b biq-bg-prim-light biq-text-white px-4 lg:px-32"
                >
                  <div className="container">
                    <h2 className="biq-font-semibold">Oprah</h2>
                    <div className="flex flex-row flex-wrap justify-center">
                      <div className="flex w-11/12 justify-center mt-8">
                        <div className="w-8/12">
                          <Img
                            fluid={
                              this.props.data.oprahImages.edges[0].node
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-8">
                      Feel like you haven’t taken a deep breath in eons? That’s
                      because you probably haven’t, says Belisa Vranich, clinical
                      psychologist and author of Breathe: 14 Days to Oxygenating,
                      Recharging, and Fueling Your Body & Brain, who’s taught
                      everyone from Fortune 500 employees to SWAT teams how to
                      breathe for stress relief and endurance.
                </div>
                    <div className="flex flex-row flex-wrap justify-center">
                      <div className="flex w-6/12 justify-center mt-8">
                        <div className="w-11/12">
                          <Img
                            fluid={
                              this.props.data.oprahImages.edges[1].node
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                      <div className="flex w-6/12 justify-center mt-8">
                        <div className="w-11/12">
                          <Img
                            fluid={
                              this.props.data.oprahImages.edges[2].node
                                .childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Layout>
        </div>
      </div>
    )
  }
}

export default Media
